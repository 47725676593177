import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../../services/configStyle'
import { actualizarEstado, statusSteps } from '../../services/api';
import { isAndroid } from 'react-device-detect';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { agregarInfo, status, statusData, hayLetras, esEditable, formatoFechaOcr, formatoFechaCuatroDigitos, validarCampo, validarValoresFormulario } from '../../services/data';


let FormularioDni = (props) => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataOCRBack, setDataOCRBack] = useState({})
    const [dataOCRFront, setDataOCRFront] = useState({})
    const [focusNombre, setFocusNombre] = useState(false)
    const [focusNacimiento, setFocusNacimiento] = useState(false)
    const [focusCurp, setFocusCurp] = useState(false)
    const [focusVigencia, setFocusVigencia] = useState(false);
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false);
    const [esCLaboral, setCLaboral] = useState(true);
    const [noOCR, setNoOCR] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [inputsValue, setInputsValue] = useState({});
    const [inputsErrors, setInputsErrors] = useState({});
    const [esPermanente, setEsPermanente] = useState(false);
    const [esMigratoria, setEsMigratoria] = useState(false);

    useEffect(() => {

        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS != null) {
            setDataOCRFront(ocrFront);
            if (ocrFront.nombreCompleto)
                setInputsValue(inputsValue => ({ ...inputsValue, ["nombreCompleto"]: ocrFront.nombreCompleto }));
            if (ocrFront.fechaNacimiento) {
                if (ocrFront.fechaNacimiento.length > 6 && ocrFront.fechaNacimiento.length < 10) {
                    let fechaOcr = formatoFechaCuatroDigitos(ocrFront.fechaNacimiento);
                    let fechaNacimiento = formatoFechaOcr(fechaOcr);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                } else {
                    let fechaNacimiento = formatoFechaOcr(ocrFront.fechaNacimiento);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                }
            }
            if (!hayLetras(ocrFront.dni)) {
                setInputsValue(inputsValue => ({ ...inputsValue, ["dni"]: (ocrFront.curp).trim() }));
            } else {
                setInputsValue(inputsValue => ({ ...inputsValue, ["dni"]: "" }));
            }
            let vigenciaC = ''
            if (ocrFront.vigencia != null) {
                if (ocrFront.identificacionPasaporte) {
                    if (ocrFront.vigencia.includes(' '))
                        vigenciaC = ocrFront.vigencia.replaceAll(' ', '/')
                    else
                        vigenciaC = ocrFront.vigencia
                    if (ocrFront.identificacionPasaporte) {
                        if (ocrFront.vigencia.includes(' '))
                            vigenciaC = ocrFront.vigencia.replaceAll(' ', '/')
                        else
                            vigenciaC = ocrFront.vigencia
                    } else {


                    }
                } else if (ocrFront.identificacionMigratoria) {
                    setEsMigratoria(true);
                    if (ocrFront.vigencia === "PERMANENTE") {
                        setEsPermanente(true);
                        vigenciaC = ocrFront.vigencia;
                    } else {
                        vigenciaC = "20" + ocrFront.vigencia.substr(-2);
                    }
                } else {
                    vigenciaC = "20" + ocrFront.vigencia.substr(-2);
                }
            }
            setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
        }

        if (localStorage.getItem("apikeyOtorgante") === "50e6a1b5-fc07-459d-a78a-de4a3cad810b") {
            setCLaboral(false)
        }

        let dataOCRBackLS = localStorage.getItem("ocrBack");

        if (dataOCRBackLS != null) {
            setDataOCRBack(JSON.parse(dataOCRBackLS));
            const ocrBack = JSON.parse(dataOCRBackLS);
            let vigenciaC = '';

            if (ocrBack.ineVigencia != null) {
                vigenciaC = ocrBack.ineVigencia;
                let formatosP = ['DD/MM/YYYY', 'DD/MM/YY'];
                if (moment(vigenciaC, formatosP, true).isValid()) {
                    setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
                }
            } else {
                if (ocrFront.vigencia != null) {
                    if (vigenciaC) {
                        setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: "20" + vigenciaC.substr(-2) }));
                    } else {
                        let vi = ""
                        if (ocrFront.vigencia.includes(' '))
                            vi = ocrFront.vigencia.replaceAll(' ', '/');
                        else
                            vi = ocrFront.vigencia;
                        setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vi }));
                    }
                } else {
                    if (ocrFront.vigencia)
                        vigenciaC = "20" + ocrFront.vigencia.substr(-2);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
                }
            }
        }
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser]);

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
    }

    const handleChange = (event) => {
        event.persist();
        let validacion = validarCampo(event.target.value, event.target.name);
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(validacion.errores);
        } else {
            setInputsErrors(validacion.errores);
        }

    };

    const validarFormulario = (event) => {
        if (event) event.preventDefault();
        let continuar = validarValoresFormulario(inputsValue);
        if (continuar.correcto) {

            let infoFormulario = []
            agregarInfo(dataUser, { description: "nombre", value: inputsValue.nombreCompleto });
            infoFormulario.push({ description: "nombre", value: inputsValue.nombreCompleto })
            let fechaNacimiento = document.getElementById("fechaNacimiento").value;
            agregarInfo(dataUser, { description: "nacimiento", value: fechaNacimiento });
            infoFormulario.push({ description: "nacimiento", value: fechaNacimiento })
            agregarInfo(dataUser, { description: "dni", value: inputsValue.dni });
            infoFormulario.push({ description: "dni", value: inputsValue.dni });
            let vinput = document.getElementById("vigenciaPasaporte").value;
            infoFormulario.push({ description: "vigencia", value: vinput });
            agregarInfo(dataUser, { description: "vigencia", value: vinput });

            setTimeout(() => {
                sendData(infoFormulario);
                history.push('/identificacion_edit');
            }, 300);
        } else {
            setInputsErrors(continuar.errores);
        }
        statusSteps("Resultados Identificación","Click en Mis datos son correctos",{},false)
    }

    const statusSE = () => {
        statusSteps("Resultados Identificación","Click en capturar nuevamente",{},false)
    }



    return (
        <Fragment>
            <form>
                <div className="ocr_data_display animate__animated">
                    <div className={["form-group", "bmd-form-group", ((focusNombre || inputsValue.nombreCompleto !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="nombreCompleto" className="bmd-label-floating">Nombre completo:</label>
                        <input type="text" className="form-control" id="nombreCompleto" name="nombreCompleto" defaultValue={inputsValue.nombreCompleto} onChange={handleChange} onFocus={e => {
                            setFocusNombre(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.nombreCompleto === "") {
                                setFocusNombre(false)
                            }
                            setInputActive(false)
                        }} disabled={(esEditable() || noOCR) ? "" : "true"} />
                        {inputsErrors.nombreCompleto && (
                            <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nombreCompleto}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusNacimiento || inputsValue.fechaNacimiento !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="fechaNacimiento" className="bmd-label-floating">Fecha de nacimiento <small>(DD/MM/AAAA)</small>:</label>
                        <NumberFormat format="##/##/####" className="form-control" id="fechaNacimiento" name="fechaNacimiento" value={inputsValue.fechaNacimiento} onChange={handleChange} onFocus={e => {
                            setFocusNacimiento(true)
                            setInputActive(true)
                        }
                        } onBlur={e => {
                            if (inputsValue.fechaNacimiento === "") {
                                setFocusNacimiento(false)
                            }
                            setInputActive(false)
                        }} disabled={(esEditable() || noOCR) ? "" : "true"} />
                        {inputsErrors.fechaNacimiento && (
                            <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaNacimiento}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusVigencia || inputsValue.vigenciaPasaporte !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="vigenciaPasaporte" className="bmd-label-floating">Vencimiento identificación:</label>
                        {
                            (esPermanente) ?
                                <input type="text" className="form-control" id="vigenciaPasaporte" name="vigenciaPasaporte" defaultValue={inputsValue.vigenciaPasaporte} onChange={handleChange} onFocus={e => {
                                    setFocusVigencia(true)
                                    setInputActive(true)
                                }} onBlur={e => {
                                    if (inputsValue.vigenciaPasaporte === "") {
                                        setFocusVigencia(false)
                                    }
                                    setInputActive(false)
                                }} />
                                :
                                <NumberFormat format="##/##/####" className="form-control" id="vigenciaPasaporte" name="vigenciaPasaporte" value={inputsValue.vigenciaPasaporte} onChange={handleChange} onFocus={e => {
                                    setFocusVigencia(true)
                                    setInputActive(true)
                                }} onBlur={e => {
                                    if (inputsValue.vigenciaPasaporte === "") {
                                        setFocusVigencia(false)
                                    }
                                    setInputActive(false)
                                }} />
                        }
                        {inputsErrors.vigenciaPasaporte && (
                            <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.vigenciaPasaporte}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusCurp || inputsValue.dni !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="dni" className="bmd-label-floating">DNI</label>
                        {
                            (esMigratoria) ?
                                <input type="text" className="form-control" id="dni" name="dni" defaultValue={inputsValue.dni} onChange={handleChange} onFocus={e => {
                                    setFocusCurp(true)
                                    setInputActive(true)
                                }} onBlur={e => {
                                    if (inputsValue.dni === "") {
                                        setFocusCurp(false)
                                    }
                                    setInputActive(false)
                                }} disabled={inputsValue.dni ? true : false} maxLength="19" />
                                :
                                <input type="text" className="form-control" id="dni" name="dni" defaultValue={inputsValue.dni} onChange={handleChange} onFocus={e => {
                                    setFocusCurp(true)
                                    setInputActive(true)
                                }} onBlur={e => {
                                    if (inputsValue.dni === "") {
                                        setFocusCurp(false)
                                    }
                                    setInputActive(false)
                                }} disabled={(esEditable() || noOCR) ? "" : "true"} />
                        }
                        {inputsErrors.dni && (
                            <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.dni}</span>
                        )}
                    </div>
                </div>
                <div className="action_buttons animate__animated">
                    <Link to={{
                        pathname: "/identificacion",
                        state: { passport: esPassport }
                    }} className="btn btn-primary forcewidth100 main_color" onClick={ statusSE }>CAPTURAR NUEVAMENTE</Link>
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color"].join(" ")} onClick={event => validarFormulario(event)}>MIS DATOS SON CORRECTOS</button>
                </div>
            </form>
        </Fragment>
    )
}

export default FormularioDni