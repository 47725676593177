import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Header from "../components/headerNA";
import Footer from "../components/footer";
// import { enviarCorreoAceptacion, obtenerScore, enviarZipAmarillos, getDocuments, finalUpdateScore } from '../services/api.js'
import { getDocuments } from '../services/api.js'
import { configStyle, obtenerValorConfig } from '../services/configStyle'
import Loader from "../components/loader";
import { status, statusError, regresarJson, sendData, evento } from '../services/data';
import Ayuda from './ayuda';

const Finalizado = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [apiKey, setApiKey] = useState('')
    const [uuid, setUuid] = useState('-')
    const [dataUser, setDataUser] = React.useState([])
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataFace, setDataFace] = useState({});
    const [isFlag, setFlag] = useState('');
    const [showHelp, setShowHelp] = useState(false);
    const [contador, setContador] = useState(0);
    const [uuidTrx, setUuidTrx] = useState('-');
    const [finished, setFinished] = useState(false);

    useEffect(() => {
        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
        }

        let uuidTrx = localStorage.getItem("uuidTrx")
        if (uuidTrx) {
            setUuidTrx(uuidTrx)
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }
        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataFaceLS = localStorage.getItem("face")
        if (dataFaceLS != null) {
            setDataFace(JSON.parse(dataFaceLS))
        }

        // obtenerScoreUser()
        localStorage.setItem('flag', 'green')
        status("consulta_score_page", "Validación de Score")
        if (regresarJson()) {
            dataLinks()
        } else {
            finalUpdateStatus()
        }
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante])

    /* const obtenerScoreUser = async () => {
        let uuidUser = localStorage.getItem("uuidUser")
        let tipo = '';
        let informacion = {};
        //console.log("entre a obtener");
        obtenerScore(uuidUser, !isMobile).then((response) => {
            //console.log("Response200",response);
            if (response.status === 200) {
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Obtener Score', 'Success', informacion, true);
                if (response.data.payload.flag) {
                    let data = { flag: response.data.payload.flag }
                    statusData("consulta_score", data);
                    let b = (response.data.payload.flag).toUpperCase()
                    setFlag(b);
                    let campos = JSON.stringify(response.data.payload.Fields)
                    localStorage.setItem("fields", campos);
                    localStorage.setItem("flag", response.data.payload.flag);
                } else {
                    console.log("sin bandera");
                    setContador(parseInt(contador) + 1)
                }
            }
            evento('Obtener score', tipo, informacion, true)

        }).catch((error) => {
            console.log("errorBandera:", error);
            setContador(parseInt(contador) + 1)
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Obtener Score', tipo, informacion, false);

        });
    } */

    /* const enviarCorreo = (e) => {
        let tipo = '';
        let informacion = {};
        let objeto = {};
        if (isFlag === "GREEN") {
            objeto.tipo_envio = 'COMPLETO';
        }else{
            objeto.tipo_envio = 'SEMAFORO';
        }
        objeto.semaforo = isFlag.toLocaleLowerCase();
        objeto.uuidUser = uuid;
        objeto.name = obtenerValorUser(dataUser, "nombreCompleto");
        objeto.email = '';
        objeto.fNac = obtenerValorUser(dataUser, "nacimiento");
        objeto.curp = (hayExcepcion()) ? obtenerValorUser(dataUser, "dni") : obtenerValorUser(dataUser, "curp");
        objeto.vigencia = obtenerValorUser(dataUser, "vigencia");
        objeto.ocrFront = JSON.parse(localStorage.getItem("ocrFront")) || {};
        objeto.ocrBack = JSON.parse(localStorage.getItem("ocrBack")) || {};
        objeto.data_user = JSON.parse(localStorage.getItem("data_user")) || [];
        objeto.detailsScore = JSON.parse(localStorage.getItem('fields')) || [];

        let fecha = ""
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (month < 10) {
            fecha = `${day}0${month}${year}`
        } else {
            fecha = `${day}${month}${year}`
        }
        let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
        partner = partner.replace(" ", "_");
        if (partner !== "") {
            partner = partner + "_"
        }
        let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
        name_otorgante = name_otorgante.replace(" ", "_");
        objeto.custom_uuid = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0];
        objeto.originOS = isIOS ? 'ios' : 'android';
        objeto.coincidencia = (dataFace.match ? "Coincide" : "Requiere validación");
        enviarCorreoAceptacion(objeto).then((response) => {
            if (response.status === 200) {
                //console.log(response);
                informacion.status = response.status;
                informacion.payload = response.data.payload;
                evento('Envío Correo', 'Success', informacion, true);
                statusData("email_check", { email: 'No se solicita correo electrónico' })
                if (regresarJson()) {
                    dataLinks()
                } else {
                    setTimeout(() => {
                        finalUpdateStatus();
                    }, 500);
                }
            }
            else {
                statusF(response.data.payload);
            }

        }).catch((error) => {
            console.error(error);
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Envío Correo', tipo, informacion, false);
            statusF(error);
            if (regresarJson()) {
                dataLinks()
            } else {
                setTimeout(() => {
                    finalUpdateStatus();
                }, 500)
            }

        });
        evento('Validación de Score', 'Click', { description: 'TERMINAR', status: 'FINALIZADO' }, true);
    } */

    const dataLinks = async () => {
        //console.log(event_t);
        let tipo = '';
        let informacion = {};
        getDocuments().then((response) => {
            informacion.status = response.status;
            informacion.payload = response.data.payload;
            evento('Obtener Documentos', 'Success', informacion, true);
            if (response.status === 200) {
                if (response.data.payload.links) {
                    let asd = JSON.stringify(response.data.payload.links)
                    localStorage.setItem('links', asd);
                }
                setTimeout(() => {
                    finalUpdateStatus();
                }, 500);
            }
            evento('Links', tipo, informacion, true)

        }).catch((error) => {
            if (error.response) {
                let { data: { message }, status } = error.response
                informacion.mensaje = message;
                informacion.status = status;
                tipo = 'Error';
            } else {
                informacion.error = error.toString();
                tipo = 'Exception';
            }
            evento('Obtener Documentos', tipo, informacion, false);

        })
    }

    const finalUpdateStatus = async () => {
        let res = convertdataJSON();
        let event_t = JSON.stringify(res)
        var json_final = {};
        let statu = "OK"
        if (isFlag === "YELLOW") {
            statu = "FAIL"
        }
        json_final = {
            "status": statu,
            "step": "finished",
            "description": event_t,
            "finished": true
        }
        let event = JSON.stringify(json_final);
        await sendData({ 'step': 'finalizado', 'event': event });
        localStorage.setItem("completado", "true");
        setFinished(true);
        statusFinal();
    }

    /* const statusF = (error) => {
        //console.log("entre a status ine");
        statusError("email_check", error, "error")
    } */

    const statusFinal = () => {
        setLoading(false);
        if (window.opener) {
            let uuidTrx = localStorage.getItem("uuidTrx");
            let event_t = "OK"

            if (regresarJson()) {
                let res = convertdataJSON();
                event_t = (res !== "") ? JSON.stringify(res) : ""
            }
            window.opener.postMessage(JSON.stringify({
                step: "completed",
                result: event_t,
                transaccion: uuidTrx,
            }), localStorage.getItem("externalReference"))
            setTimeout(() => {
                window.close();
            }, 1000);
        }
    }

    const convertdataJSON = () => {
        var fecha = ""
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
        if (month < 10) {
            fecha = `${day}0${month}${year}`
        } else {
            fecha = `${day}${month}${year}`
        }
        var jsonObj = {};
        jsonObj.uuidUser = uuid;
        jsonObj.uuidTrx = uuidTrx;
        let partner = obtenerValorConfig(dataOtorgante, "PARTNER", "")
        partner = partner.replace(" ", "_");
        if (partner !== "") {
            partner = partner + "_"
        }
        let name_otorgante = obtenerValorConfig(dataOtorgante, "NOMBRE_CORTO", "")
        name_otorgante = name_otorgante.replace(" ", "_");
        let uuidC = partner + name_otorgante + "_" + fecha + "_" + uuidTrx.split("-")[0];
        jsonObj.custom_uuid = uuidC;
        jsonObj.ocrBack = JSON.parse(localStorage.getItem("ocrBack"));
        jsonObj.ocrFront = JSON.parse(localStorage.getItem("ocrFront"));
        jsonObj.data_user = JSON.parse(localStorage.getItem("data_user"));
        jsonObj.links = JSON.parse(localStorage.getItem('links')) || "";
        jsonObj.detailsScore = JSON.parse(localStorage.getItem('fields')) || [];

        ////console.log(res);
        return jsonObj;
    }

    /* useEffect(() => {
        if (uuid && uuidTrx && isFlag && dataUser.length > 0 && dataOtorgante.length > 0 && dataFace) enviarCorreo();
    }, [uuid, uuidTrx, isFlag, dataUser, dataOtorgante, dataFace]); */

    return (
        <div className="main_gradient">
            <Header ruta="finalizado" show={() => setShowHelp(true)} />
            <div className="main_text_container">
                <h1 className="animate__animated animate__fadeIn animate__delay-2s">¡Muchas Gracias!</h1>
                <div className="animate__animated animate__fadeIn animate__delay-2s">
                    <p>Has concluido tu proceso con éxito. </p>

                </div>
                {/*
                    (isFlag !== "RED") ?
                        <h1 className="animate__animated animate__fadeIn animate__delay-1s">¡Muchas Gracias!</h1>
                        :
                        <h1 className="animate__animated animate__fadeIn animate__delay-1s">Proceso no exitoso</h1>
                */}
                {/*
                    (isFlag === "GREEN") ?
                        <div className="animate__animated animate__fadeIn animate__delay-2s">
                            <p>Has completado tu validación exitosamente. </p>

                        </div>
                        :
                        (isFlag === "YELLOW") ?
                            <div className="animate__animated animate__fadeIn animate__delay-2s">
                                <p>Tu validación no fue completada exitosamente, por favor inténtalo de nuevo más tarde.</p>
                                <br />

                            </div>
                            :
                            <div className="animate__animated animate__fadeIn animate__delay-2s">
                                <p>Lo sentimos tu validación <b>no fue aceptada</b>, te sugerimos <b>verificar tu documentación</b> e intentarlo mas adelante siguiendo <b>detenidamente las instrucciones</b>.</p>
                                <br />

                            </div>

                */}
            </div>
            <p className="text-center animate__animated animate__fadeIn animate__delay-2s" >Folio: <br />
                <b>{uuidTrx.split("-")[0]}</b>
            </p>
            {
                (localStorage.getItem("package") !== null) ?
                    <div className="action_buttons">
                        <a className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__delay-2s" href={"" + localStorage.getItem("package") + "://folio=" + localStorage.getItem("uuidUser")}>
                            Volver al origen
                        </a>
                    </div>
                    :
                    (!window.opener) &&
                    <div className="action_buttons">
                        <button type="button" onClick={(e) => {
                            evento('Validación de Score', 'Click', { description: 'TERMINAR' }, true);
                            setTimeout(() => {
                                history.push('/' + apiKey)
                            }, 300);
                        }} className="btn btn-raised btn-primary forcewidth100 main_bg_color animate__animated animate__fadeIn animate__delay-2s" disabled={!finished}>CONTINUAR</button>
                    </div>
            }
            {(loading) && <Loader />}

            <Footer />
            {showHelp ? <Ayuda hide={() => setShowHelp(false)} /> : ""}
        </div>
    )

}

export default Finalizado