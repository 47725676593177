import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { configStyle, obtenerValorConfig } from '../../services/configStyle'
import { actualizarEstado, enviarRenapo, enviarIne, consultarListasNegras, statusSteps } from '../../services/api';
import { isAndroid } from 'react-device-detect';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { hayExcepcion, agregarInfo, status, statusData, esEditable, formatoFechaOcr, formatoFechaCuatroDigitos, validarCampo, validarValoresFormulario, evento } from '../../services/data'
import Info from '../../components/encuentra_info';


let FormularioIneConsulta = (props) => {
    const history = useHistory();
    const [dataOtorgante, setDataOtorgante] = useState([])
    const [dataOCRBack, setDataOCRBack] = useState({})
    const [dataOCRFront, setDataOCRFront] = useState({})
    const [focusNombre, setFocusNombre] = useState(false)
    const [focusNacimiento, setFocusNacimiento] = useState(false)
    const [focusCurp, setFocusCurp] = useState(false)
    const [focusVigencia, setFocusVigencia] = useState(false)
    const [focusClave, setfocusClave] = useState(false);
    const [focusRegistro, setfocusRegistro] = useState(false);
    const [focusEmision, setfocusEmision] = useState(false);
    const [focusCIC, setfocusCIC] = useState(false);
    const [dataUser, setDataUser] = React.useState([])
    const [inputActive, setInputActive] = useState(false)
    const [esPassport, setEsPassport] = useState(false);
    const [esIne, setEsIne] = useState(true);
    const [esCLaboral, setCLaboral] = useState(true);
    const [noOCR, setNoOCR] = useState(false);
    const [apiKey, setApiKey] = useState('');
    const [inputsValue, setInputsValue] = useState({});
    const [inputsErrors, setInputsErrors] = useState({});
    const [nombres, setNombres] = useState('');
    const [apellidoPaterno, setApellidoPaterno] = useState('');
    const [apellidoMaterno, setApellidoMaterno] = useState('');
    const [fechaListasNegras, setFechaListasNegras] = useState('');
    const [uuid, setUuid] = useState('');

    useEffect(() => {
        console.log("inivio");

        let no_OCR = localStorage.getItem("intentsData")
        if (no_OCR !== null) {
            if (no_OCR === "OCR")
                setNoOCR(true)
        }

        let apikeyOtorgante = localStorage.getItem("apikeyOtorgante")
        if (apikeyOtorgante !== null) {
            setApiKey(apikeyOtorgante)
        }

        let dataOtorganteLS = localStorage.getItem("dataOtorgante")
        if (dataOtorganteLS != null) {
            setDataOtorgante(JSON.parse(dataOtorganteLS))
        }
        let dataUserLS = localStorage.getItem("data_user")
        if (dataUserLS != null) {
            setDataUser(JSON.parse(dataUserLS))
        }

        let uuidUser = localStorage.getItem("uuidUser")
        if (uuidUser != null) {
            setUuid(uuidUser)
        }
        let dataOCRFrontLS = localStorage.getItem("ocrFront")
        const ocrFront = JSON.parse(dataOCRFrontLS)
        if (dataOCRFrontLS != null) {
            setDataOCRFront(ocrFront);
            if (ocrFront.nombreCompleto)
                setInputsValue(inputsValue => ({ ...inputsValue, ["nombreCompleto"]: ocrFront.nombreCompleto }));
            if (ocrFront.claveElector)
                setInputsValue(inputsValue => ({ ...inputsValue, ["clave"]: (ocrFront.claveElector).trim() }));
            if (ocrFront.anioRegistro)
                setInputsValue(inputsValue => ({ ...inputsValue, ["fechaRegistro"]: (ocrFront.anioRegistro).trim() }));
            if (ocrFront.numeroRegistro)
                setInputsValue(inputsValue => ({ ...inputsValue, ["numeroEmision"]: (ocrFront.numeroRegistro).trim() }));
            if (ocrFront.fechaNacimiento) {
                if (ocrFront.fechaNacimiento.length > 6 && ocrFront.fechaNacimiento.length < 10) {
                    let fechaOcr = formatoFechaCuatroDigitos(ocrFront.fechaNacimiento);
                    let fechaNacimiento = formatoFechaOcr(fechaOcr);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                } else {
                    let fechaNacimiento = formatoFechaOcr(ocrFront.fechaNacimiento);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["fechaNacimiento"]: fechaNacimiento }));
                }
            }
            if (ocrFront.curp)
                if (ocrFront.curp.length > 14)
                    setInputsValue(inputsValue => ({ ...inputsValue, ["curp"]: (ocrFront.curp).trim() }));
            let vigenciaC = ''
            if (ocrFront.vigencia != null){
                vigenciaC = "20" + ocrFront.vigencia.substr(-2);
                setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
            }
            /* Listas negras */
            if (ocrFront.nombres)
                setNombres(ocrFront.nombres);
            if (ocrFront.apellidoMaterno)
                setApellidoMaterno(ocrFront.apellidoMaterno);
            if (ocrFront.apellidoPaterno)
                setApellidoPaterno(ocrFront.apellidoPaterno);
            if (ocrFront.fechaNacimiento){
                let fecha = ocrFront.fechaNacimiento;
                let dateArray = fecha.split("/");
                let fechaNacimiento = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
                console.log(fechaNacimiento);
                setFechaListasNegras(fechaNacimiento);
            }
        }

        if (localStorage.getItem("apikeyOtorgante") === "50e6a1b5-fc07-459d-a78a-de4a3cad810b") {
            setCLaboral(false)
        }

        let dataOCRBackLS = localStorage.getItem("ocrBack");

        if (dataOCRBackLS != null) {
            setDataOCRBack(JSON.parse(dataOCRBackLS));
            const ocrBack = JSON.parse(dataOCRBackLS);
            let vigenciaC = '';

            if (ocrBack.ineVigencia != null) {
                vigenciaC = ocrBack.ineVigencia;
                let formatosP = ['DD/MM/YYYY', 'DD/MM/YY'];
                if (moment(vigenciaC, formatosP, true).isValid()) {
                    // setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
                    setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: "20" + vigenciaC.substr(-2) }));
                }
            } else {
                if (ocrFront.vigencia != null) {
                    if (vigenciaC) {
                        setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: "20" + vigenciaC.substr(-2) }));
                    } else {
                        let vi = ""
                        if (ocrFront.vigencia.includes(' '))
                            vi = ocrFront.vigencia.replaceAll(' ', '/');
                        else
                            vi = ocrFront.vigencia;
                        setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vi }));
                    }
                } else {
                    if (ocrFront.vigencia)
                        vigenciaC = "20" + ocrFront.vigencia.substr(-2);
                    setInputsValue(inputsValue => ({ ...inputsValue, ["vigencia"]: vigenciaC }));
                }
            }
            if (ocrBack.cicMRZ)
                setInputsValue(inputsValue => ({ ...inputsValue, ["cic"]: (ocrBack.cicMRZ).trim() }));
        }
    }, [])

    useEffect(() => {
        configStyle(dataOtorgante)
    }, [dataOtorgante]);

    useEffect(() => {
        localStorage.setItem("data_user", JSON.stringify(dataUser))
    }, [dataUser]);

    const sendData = (infoFormulario) => {
        var jsonObj = {};
        jsonObj.infoPersonal = infoFormulario;
        statusData("datos_personales_ocr", jsonObj)
    }

    const handleChange = (event) => {
        event.persist();
        let validacion = validarCampo(event.target.value, event.target.name);
        if (validacion.correcto) {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, [event.target.name]: '' }));
        } else {
            setInputsValue(inputsValue => ({ ...inputsValue, [event.target.name]: event.target.value }));
            setInputsErrors(inputsErrors => ({ ...inputsErrors, ...validacion.errores }));
        }

    };

    const validarFormulario = (event) => {
        if (event) event.preventDefault();
        // console.log("inputsValue",inputsValue);
        let continuar = validarValoresFormulario(inputsValue);
        console.log("continuar",continuar);
        if (continuar.correcto) {
            if (obtenerValorConfig(dataOtorgante, 'ENABLE_RENAPO', "true") === "true") {
                let tipo = '';
                let informacion = {};
                enviarRenapo(inputsValue.curp).then(response => {
                    console.log(response);
                    evento('Enable Renapo', tipo, informacion, true);

                }).catch(error => {
                    console.log("Error RENAPO: " + error);
                    evento('Error Enable Renapo', tipo, informacion, true);

                });
            }

            if (obtenerValorConfig(dataOtorgante, 'ENABLE_INE', "true") === "true" && esIne) {
                let tipo = '';
                let informacion = {};
                enviarIne(inputsValue.cic, inputsValue.clave, inputsValue.numeroEmision).then(response => {
                    console.log(response);
                    evento('Enable INE', tipo, informacion, true);

                }).catch(error => {
                    console.log("Error INE: " + error);
                    evento('Error Enable INE', tipo, informacion, true);

                });
            }

            let infoFormulario = []
            agregarInfo(dataUser, { description: "nombre", value: inputsValue.nombreCompleto });
            infoFormulario.push({ description: "nombre", value: inputsValue.nombreCompleto })
            let fechaNacimiento = document.getElementById("fechaNacimiento").value;
            agregarInfo(dataUser, { description: "nacimiento", value: fechaNacimiento });
            infoFormulario.push({ description: "nacimiento", value: fechaNacimiento })
            agregarInfo(dataUser, { description: "curp", value: inputsValue.curp });
            infoFormulario.push({ description: "curp", value: inputsValue.curp });
            infoFormulario.push({ description: "vigencia", value: "31/12/20" + inputsValue.vigencia.substr(-2) });
            agregarInfo(dataUser, { description: "vigencia", value: "31/12/20" + inputsValue.vigencia.substr(-2) });

            /* Consulta listas negras */
            /* consultarListasNegras(uuid, nombres, apellidoPaterno, apellidoMaterno, fechaListasNegras).then(response => {
                console.log(response);
            }).catch(error => {
                console.log("Error: ", error);
            });
            */
            setTimeout(() => {
                sendData(infoFormulario);
                history.push('/identificacion_edit');
            }, 300);
        } else {
            setInputsErrors(continuar.errores);
        }
        statusSteps("Resultados Identificación","Click en Mis datos son correctos",{},false)

    }

    const statusSE = () => {
        statusSteps("Resultados Identificación","Click en capturar nuevamente",{},false)
    }


    return (
        <Fragment>
            <form id="FormularioIneConsulta">
                <div className="ocr_data_display animate__animated">
                    <div className={["form-group", "bmd-form-group", ((focusNombre || inputsValue.nombreCompleto !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="nombreCompleto" className="bmd-label-floating">Nombre completo:</label>
                        <input type="text" className="form-control" id="nombreCompleto" name="nombreCompleto" defaultValue={inputsValue.nombreCompleto} onChange={handleChange} onFocus={e => {
                            setFocusNombre(true)
                            setInputActive(true)
                        }} onBlur={e => {
                            if (inputsValue.nombreCompleto === "") {
                                setFocusNombre(false)
                            }
                            setInputActive(false)
                        }} disabled={(esEditable() || noOCR) ? "" : "true"} />
                        {inputsErrors.nombreCompleto && (
                            <span id="ht-nombre" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.nombreCompleto}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusNacimiento || inputsValue.fechaNacimiento !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="fechaNacimiento" className="bmd-label-floating">Fecha de nacimiento <small>(DD/MM/AAAA)</small>:</label>
                        <NumberFormat format="##/##/####" className="form-control" id="fechaNacimiento" name="fechaNacimiento" value={inputsValue.fechaNacimiento} onChange={handleChange} onFocus={e => {
                            setFocusNacimiento(true)
                            setInputActive(true)
                        }
                        } onBlur={e => {
                            if (inputsValue.fechaNacimiento === "") {
                                setFocusNacimiento(false)
                            }
                            setInputActive(false)
                        }} disabled={(esEditable() || noOCR) ? "" : "true"} />
                        {inputsErrors.fechaNacimiento && (
                            <span id="ht-nacimiento" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaNacimiento}</span>
                        )}
                    </div>
                    <div className={["form-group", "bmd-form-group", ((focusClave || inputsValue.clave !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="clave" className="bmd-label-floating">Clave de elector:<small> *Dato frontal</small></label>
                        <input type="text" className="form-control" id="clave" name="clave" maxLength="20" defaultValue={inputsValue.clave} onChange={handleChange} onFocus={e => {
                            setfocusClave(true)
                        }} onBlur={e => {
                            if (inputsValue.clave === "") {
                                setfocusClave(false)
                            }
                        }} />
                        {inputsErrors.clave && (
                            <span id="ht-clave" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.clave}</span>
                        )}
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className={["form-group", "bmd-form-group", ((focusRegistro || inputsValue.fechaRegistro !== "") ? "is-focused" : "")].join(" ")}>
                                <label htmlFor="fechaRegistro" className="bmd-label-floating">Año de registro:</label>
                                <input type="text" className="form-control" id="fechaRegistro" name="fechaRegistro" maxLength="4" defaultValue={inputsValue.fechaRegistro} onChange={handleChange} onFocus={e => {
                                    setfocusRegistro(true)
                                }
                                } onBlur={e => {
                                    if (inputsValue.fechaRegistro === "") {
                                        setfocusRegistro(false)
                                    }
                                }} />
                                {inputsErrors.fechaRegistro && (
                                    <span id="ht-registro" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.fechaRegistro}</span>
                                )}
                            </div>
                        </div>
                        <div className="col">
                            <div className={["form-group", "bmd-form-group", ((focusEmision || inputsValue.numeroEmision !== "") ? "is-focused" : "")].join(" ")}>
                                <label htmlFor="numeroEmision" className="bmd-label-floating">Número de emisión:</label>
                                <input type="text" className="form-control" id="numeroEmision" name="numeroEmision" defaultValue={inputsValue.numeroEmision} maxLength="2" onChange={handleChange} onFocus={e => {
                                    setfocusEmision(true)
                                }
                                } onBlur={e => {
                                    if (inputsValue.numeroEmision === "") {
                                        setfocusEmision(false)
                                    }
                                }} />
                                {inputsErrors.numeroEmision && (
                                    <span id="ht-emision" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.numeroEmision}</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={["form-group", "bmd-form-group", ((focusCIC || inputsValue.cic !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="cic" className="bmd-label-floating">CIC/OCR:<small> *Dato reverso</small></label>
                        <input type="text" className="form-control" id="cic" name="cic" maxLength="13" defaultValue={inputsValue.cic} onChange={handleChange} onFocus={e => {
                            setfocusCIC(true)
                        }} onBlur={e => {
                            if (inputsValue.cic === "") {
                                setfocusCIC(false)
                            }
                        }} />
                        {inputsErrors.cic && (
                            <span id="ht-CIC" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.cic}</span>
                        )}
                    </div>

                    <div className={["form-group", "bmd-form-group", ((focusCurp || inputsValue.curp !== "") ? "is-focused" : "")].join(" ")}>
                        <label htmlFor="curp" className="bmd-label-floating">CURP:</label>
                        <input type="text" className="form-control uppercase" id="curp" name="curp" defaultValue={inputsValue.curp} maxLength="19" onChange={handleChange} onFocus={e => {
                            setFocusCurp(true)
                        }} onBlur={e => {
                            if (inputsValue.curp === "") {
                                setFocusCurp(false)
                            }
                        }} />
                        {inputsErrors.curp && (
                            <span id="ht-curp" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.curp}</span>
                        )}
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className={["form-group", "bmd-form-group", ((focusVigencia || inputsValue.vigencia !== "") ? "is-focused" : "")].join(" ")}>
                                <label htmlFor="vigencia" className="bmd-label-floating">Vencimiento identificación:</label>
                                <input type="text" className="form-control" id="vigencia" name="vigencia" maxLength="4" defaultValue={inputsValue.vigencia} onChange={handleChange} onFocus={e => {
                                    setFocusVigencia(true)
                                }} onBlur={e => {
                                    if (inputsValue.vigencia === "") {
                                        setFocusVigencia(false)
                                    }
                                }} />
                                {inputsErrors.vigencia && (
                                    <span id="ht-vigencia" className="helper-text ht" data-error="wrong" data-success="right">{inputsErrors.vigencia}</span>
                                )}
                            </div>
                        </div>
                        <div className="col">

                        </div>
                    </div>
                    <Info />
                </div>
                <div className="action_buttons animate__animated">
                    <Link to={{
                        pathname: "/identificacion",
                        state: { passport: esPassport }
                    }} className="btn btn-primary forcewidth100 main_color" onClick={statusSE} >CAPTURAR NUEVAMENTE</Link>
                    <button type="button" className={["btn btn-raised btn-primary forcewidth100 main_bg_color"].join(" ")} onClick={event => validarFormulario(event)}>MIS DATOS SON CORRECTOS</button>
                </div>
            </form>
        </Fragment>
    )
}

export default FormularioIneConsulta